const exports = {};

exports.test = {
  foo() { console.log('foo'); },
  bar() { console.log('bar'); },
  baz() { console.log('baz'); },
};

exports.rowToObject = function (row, fields) {
  console.log('rowToObject');
  const newObj = {};
  for (let i = 0; i < row.length; i += 1) {
    newObj[fields[i]] = row[i];
  }
  return newObj;
};

exports.CSVToArray = function (strData, _strDelimiter) {
  // Check to see if the delimiter is defined. If not,
  // then default to comma.
  const strDelimiter = (_strDelimiter || ',');
  // Create a regular expression to parse the CSV values.
  const objPattern = new RegExp(
    (
      // Delimiters.
      `(\\${strDelimiter}|\\r?\\n|\\r|^)`
      // Quoted fields.
      + '(?:"([^"]*(?:""[^"]*)*)"|'
      // Standard fields.
      + `([^"\\${strDelimiter}\\r\\n]*))`
    ),
    'gi',
  );

  // Create an array to hold our data. Give the array
  // a default empty first row.
  const arrData = [[]];
  // Create an array to hold our individual pattern
  // matching groups.
  let arrMatches = null;
  // Keep looping over the regular expression matches
  // until we can no longer find a match.
  while ((arrMatches = objPattern.exec(strData)) !== null ) { // eslint-disable-line
    // Get the delimiter that was found.
    const strMatchedDelimiter = arrMatches[1];
    // Check to see if the given delimiter has a length
    // (is not the start of string) and if it matches
    // field delimiter. If id does not, then we know
    // that this delimiter is a row delimiter.
    if (
      strMatchedDelimiter.length
      && strMatchedDelimiter !== strDelimiter
    ) {
      // Since we have reached a new row of data,
      // add an empty row to our data array.
      arrData.push([]);
    }
    let strMatchedValue;
    // Now that we have our delimiter out of the way,
    // let's check to see which kind of value we
    // captured (quoted or unquoted).
    if (arrMatches[2]) {
      // We found a quoted value. When we capture
      // this value, unescape any double quotes.
      strMatchedValue = arrMatches[2].replace(
        new RegExp('""', 'g'),
        '"',
      );
    } else {
      // We found a non-quoted value.
      strMatchedValue = arrMatches[3]; // eslint-disable-line
    }
    // Now that we have our value string, let's add
    // it to the data array.
    arrData[arrData.length - 1].push(strMatchedValue);
  }
  // Return the parsed data.
  return (arrData);
};

exports.truncate = function (inputStr, maxChar) {
  return `${inputStr.substring(0, maxChar)}...`;
};

export default exports;
