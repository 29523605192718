<template>
  <map-maker />
</template>

<script>
import MapMaker from '../components/MapMaker.vue';

export default {
  name: 'Home',

  components: {
    MapMaker,
  },
};
</script>
